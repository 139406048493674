import * as React from 'react';
import { Page } from '@alexdisler/components-landing';
import { TextBlock } from '@alexdisler/components-landing';
import { ContractText } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems, copyright } from '../../providers/data';

interface Props {
  // in
  // out
}

export class PrivacyPage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="Privacy Policy"
        name={name}
        copyright={copyright}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        {this.renderPageContent()}
      </Page>
    );
  }

  private renderPageContent = () => {
    return (
      <TextBlock>
        <ContractText
          doc="privacy"
          entity="BinaryPattern"
        />
      </TextBlock>
    );
  }

}