import * as React from 'react';
import { Intro } from '@alexdisler/components-landing';
import { Page } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems, copyright } from '../../providers/data';
import { Centered } from '@alexdisler/components-landing';
import s from './home-page.module.css';

interface Props {
  // in
  // out
}

export class HomePage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="Home Page"
        transparentMenu={true}
        name={name}
        copyright={copyright}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        <Intro>
          <Centered style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={s.mainText}>We create mobile communication apps</div>
            <div className={s.subText}>Get a second phone line on your device</div>
          </Centered>
          <div className={s.bgImage}>
            <img src="/bg.jpg" />
          </div>
        </Intro>
      </Page>
    );
  }

}