import { MenuItemLink } from '@alexdisler/components-landing';

export const name = 'BinaryPattern';

export const copyright = `Copyright © 2019 ${name} LLC. All rights reserved.`;

export const headerMenuItems: Array<MenuItemLink> = [
  { label: 'iOS Apps', path: 'https://itunes.apple.com/us/developer/binarypattern/id874400678?mt=8', external: true },
  { label: 'Android Apps', path: 'https://play.google.com/store/apps/developer?id=BinaryPattern+LLC', external: true },
];

export const footerMenuItems: Array<MenuItemLink> = [
  { label: 'Support', path: 'https://binarypattern.zendesk.com/hc/en-us', external: true },
  { label: 'Terms', path: '/terms' },
  { label: 'Privacy', path: '/privacy' },
];