import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ScrollToTop } from '@alexdisler/components-landing';
import createBrowserHistory from 'history/createBrowserHistory';
import { CssGlobal } from '@alexdisler/components-landing';
import * as pages from './pages';
const customHistory = createBrowserHistory();

import ReactGA from 'react-ga';
ReactGA.initialize('UA-145234786-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  public render() {
    return (
      <>
        <CssGlobal />
        <Router history={customHistory} >
          <ScrollToTop>
            <Switch>
              <Route exact={true} path="/" component={(props: any) => { return <pages.HomePage {...props} />; }} />
              <Route path="/terms" component={(props: any) => { return <pages.TermsPage {...props} />; }} />
              <Route path="/privacy" component={(props: any) => { return <pages.PrivacyPage {...props} />; }} />
              <Route component={pages.Error404Page} />
            </Switch>
          </ScrollToTop>
        </Router>
      </>
    );
  }

}

export default App;
